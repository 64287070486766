import React from "react";
// import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

import { FaHatCowboy } from "react-icons/fa";
// import { IoMdHammer } from "react-icons/io";
function HeroVideo({ urlVideo, title, texts, Slogan2 }) {
    // const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[30px] md:bottom-[100px] lg:bottom-[90px] md:text-start text-center text-white">
                    <div className="w-full text-center p-4">
                        <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">{title}</h1>
                        <p className="px-5 md:px-[20%]">{texts}</p>
                        <ButtonContent btnStyle="three" />
                    </div>
                </div>
            </div>
            <div className=" md:w-[70%] w-[100%] bg-2  md:-mt-14 -mt-20 relative md:py-8 py-2 homi">
                <ul className="flex md:flex-row flex-col justify-center items-center ml-5 md:ml-0 space-x-5">
                    <h5 className="flex items-center pl-5" data-aos="zoom-in">
                        <FaHatCowboy
                            fontSize={120}
                            color="#ee660b"
                            className="text-[#ee660b] mr-2"
                        /></h5>
                    <h2 className="capitalize text-white">
                        {Slogan2}
                    </h2>

                </ul>
            </div>
        </div>
    );
}
export default HeroVideo;